<template>
    <section v-if="isVisible" v-show="isAvailable" class="main-page-partners">
        <div class="a-container">
            <s-main-page-tag v-bind="tag({name: 'title', isDangerous: true})" />
            <div v-if="isLoaded" class="content">
                <div class="slider-wrapper">
                    <a-slider :options="options" :class="{'is-control-disabled': !isSlider}">
                        <a-slide v-for="(item, itemIndex) in content.list" :key="`slide-${itemIndex}`">
                            <dynamic-link v-if="item.to" to="/" native-type="a">
                                <a-picture :link="item.logo" />
                            </dynamic-link>
                            <a-picture v-else :link="item.logo" />
                        </a-slide>
                    </a-slider>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import breakpoints from '@core/mixins/breakpoint';
import mixin from '../components/mixin.js';
import SMainPageTag from '../components/tag.vue';

export default {
    name: 'SMainPagePartners',
    components: {
        APicture,
        ASlide,
        ASlider,
        DynamicLink,
        SMainPageTag,
    },
    mixins: [breakpoints, mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isLoaded: false,
        };
    },
    computed: {
        isVisible() {
            return this.content?.title || this.content?.list?.length;
        },
        isSlider() {
            return this.content.list.length > this.quantity;
        },
        quantity() {
            const items = {
                576: 3,
                768: 3,
                1024: 4,
                1280: 6,
                1440: 7,
            };
            const width = this.breakpoints[this.currentBreakpoint];
            return items[width] || 2;
        },
        options() {
            return {
                useLoop: true,
                spaceBetween: 20,
                slidesPerGroup: 1,
                slidesPerView: 'auto',
                showNavigation: true,
                showPagination: true,
                allowTouchMove: true,
                slideToClickedSlide: false,
                customPagination: { type: 'bullets' },
                breakpoints: {
                    768: { allowTouchMove: false },
                },
            };
        },
    },
    mounted() {
        this.isLoaded = true;
    },
};
</script>

<style lang="postcss" scoped>
.main-page-partners {
    padding: 24px 0;
    @media (--viewport-desktop-wide) {
        padding: 40px;
    }
    .title {
        @mixin lead;
        padding: 0 32px;
        text-align: center;
        color: var(--av-fixed-primary);
        @media (--viewport-tablet) {
            padding: 0;
            @mixin title
        }
        @media (--viewport-desktop) {
            @mixin display
        }
    }
    .content {
        margin-top: 32px;
    }

    .slider-wrapper {
        &:deep(.a-slider) {
            width: 296px;
            overflow: hidden;
            margin: 0 auto;
            @media (--viewport-mobile-wide) {
                width: 454px;
            }
            @media (--viewport-desktop) {
                width: 612px;
            }
            @media (--viewport-desktop-wide) {
                width: 928px;
            }
            @media (--viewport-desktop-large) {
                width: 1086px;
            }
            position: initial;
            .a-slide {
                opacity: 1;
                display: flex;
                height: 76px;
                width: 138px;
                align-items: center;
                justify-content: center;
                .a-picture__img {
                    max-width: 119px;
                    max-height: 76px;
                }
            }
            .a-slider__dot {
                &::before {
                    background-color: var(--av-nav-primary);
                }
            }
            .a-slider__dot_active {
                &::before {
                    background-color: var(--av-brand-secondary);
                }
            }
            .a-slider__nav {
                justify-content: center;
                @media (--viewport-tablet) {
                    margin: 0;
                }
            }
            .a-slider__dots {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                @media (--viewport-tablet) {
                    display: none;
                }
            }
            .a-slider__controls {
                display: none;
                @media (--viewport-tablet) {
                    bottom: 0;
                    margin: 0;
                    z-index: 0;
                    height: 76px;
                    display: flex;
                    width: auto;
                    position: absolute;
                    align-items: center;
                    inset-inline-end: 0;
                    inset-inline-start: 0;
                    background: transparent;
                    justify-content: space-between;
                    padding: 0 var(--container-paddings-mobile-wide);
                }
                @media (--viewport-desktop-wide) {
                    padding: 0 var(--container-paddings-desktop-wide);
                }
                @media (--viewport-desktop-large) {
                    padding: 0;
                }
                .a-slider__control {
                    margin: 0;
                }
            }
            .a-slider__wrapper  {
                z-index: 1;
            }
            &.is-control-disabled {
                .a-slider__control {
                    display: none;
                }
            }
        }
    }
}
</style>
