<template>
    <div class="video-cards">
        <div class="cards-wrapper">
            <video-card-extended
                v-for="(card, i) in cardsLimited"
                :key="i"
                v-bind="card"
            />
        </div>
        <a-button
            v-if="showMoreButtonVisible"
            :text="showMoreText"
            type="main"
            glyph="arrow-down"
            class="show-more-button"
            :event="event?.showMore"
            @click="onShowMoreClick"
        />
        <a-button
            v-if="showLessButtonVisible"
            :text="showLessText"
            type="main"
            glyph="arrow-up"
            class="show-more-button"
            :event="event?.showLess"
            @click="onShowLessClick"
        />
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import VideoCardExtended from '@core/components/video-card-extended/video-card-extended.vue';

const CARDS_TO_SHOW = 6;

export default {
    name: 'VideoCards',

    components: {
        AButton,
        VideoCardExtended,
    },

    props: {
        cards: {
            type: Array,
            default: () => ([]),
        },
        cardsToShow: {
            type: Number,
            default: CARDS_TO_SHOW,
        },
        showMoreText: {
            type: String,
            default: 'More videos',
        },
        showLessText: {
            type: String,
            default: 'Show less',
        },
        event: {
            type: Object,
            default: undefined,
        },
    },

    data: () => ({
        pagesToShow: 1,
    }),

    computed: {
        maxPages() {
            return Math.ceil(this.cards.length / this.cardsToShow);
        },

        cardsLimited() {
            const cardsToShow = this.cardsToShow * this.pagesToShow;
            return this.cards.slice(0, cardsToShow);
        },

        showMoreButtonVisible() {
            return this.pagesToShow < this.maxPages;
        },

        showLessButtonVisible() {
            if (this.maxPages < 2) return false;
            return this.pagesToShow === this.maxPages;
        },
    },

    methods: {
        onShowMoreClick() {
            this.pagesToShow = Math.min(this.pagesToShow + 1, this.maxPages);
        },
        onShowLessClick() {
            this.pagesToShow = 1;
        },
    },
};
</script>

<style lang="postcss" scoped>
.video-cards {
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;

    .cards-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;

        @media (--viewport-mobile-wide) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        @media (--viewport-desktop-wide) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .show-more-button {
        width: 100%;
        margin: 16px auto 0;
        justify-content: center;

        @media (--viewport-mobile-wide) {
            width: 30%;
        }
    }
}
</style>
