<template>
    <section v-if="isAvailable && isVisible" class="main-page-casestudy">
        <div class="a-container">
            <div class="content">
                <s-main-page-tag v-bind="tag({name: 'title'})" />
                <div v-if="isLoaded" class="slider-wrapper">
                    <a-slider :options="options">
                        <a-slide v-for="(item, itemIndex) in content.list" :key="itemIndex">
                            <div v-if="item.person || item.photo" class="person">
                                <a-picture v-if="item.photo" class="photo" v-bind="item.photo" />
                                <div class="info">
                                    <a-picture v-if="item.logo" v-bind="item.logo" />
                                    <p v-if="item.person" class="name" v-html="item.person" />
                                </div>
                            </div>
                            <div v-if="item.title || item.description" class="quote">
                                <p v-if="item.title" class="title" v-html="item.title" />
                                <p v-if="item.description" class="description" v-html="item.description" />
                                <a-link v-if="item.link" v-bind="item.link" />
                            </div>
                        </a-slide>
                    </a-slider>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import breakpoints from '@core/mixins/breakpoint';
import mixin from '../components/mixin.js';
import SMainPageTag from '../components/tag.vue';

export default {
    name: 'SMainPageCasestudy',
    components: {
        ALink,
        APicture,
        ASlide,
        ASlider,
        SMainPageTag,
    },
    mixins: [breakpoints, mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    data() {
        const isActive = this.content.list.length > 1;
        return {
            isLoaded: false,
            options: {
                showNavigation: isActive,
                showPagination: isActive,
                slidesPerView: 1,
                spaceBetween: 0,
                slidesPerGroup: 1,
                customPagination: { type: 'bullets' },
                skipInit: !isActive,
            },
        };
    },
    computed: {
        isVisible() {
            return this.content?.title || this.content.list?.length;
        },
    },
    mounted() {
        if (!this.content.list?.length) return;
        this.isLoaded = true;
    },
};
</script>

<style lang="postcss" scoped>
.main-page-casestudy {
    margin: 48px 0 112px;
     @media (--viewport-desktop) {
        margin-bottom: 0;
     }
    .content {
        gap: 24px;
        display: flex;
        flex-direction: column;
        padding: 24px 16px 16px;
        border-radius: 8px;
        background: var(--av-inversed-primary);
        box-shadow: 0 4px 8px 0 rgba(36, 49, 67, 0.1);
        border: 1px solid var(--av-brand-secondary-light);
        @media (--viewport-tablet) {
            padding: 24px;
        }
        @media (--viewport-desktop) {
            gap: 56px;
            padding: 40px 24px;
            position: relative;
            flex-direction: row;
        }
        @media (--viewport-desktop-wide) {
            padding: 40px;
        }
    }
    .title {
        @mixin display-accent;
        text-align: center;
        color: var(--av-nav-primary);
        @media (--viewport-desktop) {
            width: 204px;
            text-align: start;
        }
    }
    .slider-wrapper {
        margin: 0;
        @media (--viewport-desktop) {
            width: calc(100% - 260px);
        }
        .person {
            gap: 16px;
            display: flex;
            flex-direction: row;
            @media (--viewport-tablet) {
                flex-direction: column;
                margin-inline-end: 16px;
            }
            .photo {
                min-width: 112px;
                height: 112px;
                &:deep(.a-picture) {
                    height: 100%;
                    border-radius: 100%;
                }
            }
            .name {
                @mixin caption;
                margin-top: 8px;
                color: var(--av-fixed-light);
                @media (--viewport-tablet) {
                    margin-top: 0
                }
            }
            .info {
                @media (--viewport-tablet) {
                    width: 178px;
                }
            }
        }
        .quote {
            display: flex;
            flex-direction: column;
            .title {
                @mixin lead-accent;
                width: 100%;
                margin-top: 16px;
                text-align: start;
                color: var(--av-brand-primary);
                @media (--viewport-tablet) {
                    margin-top: 0
                }
                @media (--viewport-desktop-wide) {
                    @mixin title-accent;
                }
            }
            .description {
                @mixin paragraph;
                margin-top: 16px;
                color: var(--av-fixed-light);
            }
        }
        &:deep(.a-slider) {
            position: initial;
            .a-slide {
                width: 100%;
                display: flex;
                flex-direction: column;
                @media (--viewport-tablet) {
                    gap: 24px;
                    flex-direction: row;
                }
                .a-picture__img {
                    margin: 0;
                }
            }
            .a-slider__dot {
                &::before {
                    background-color: var(--av-nav-primary);
                }
                @media (--viewport-desktop) {
                    display: none;
                }
            }
            .a-slider__dot_active {
                &::before {
                    background-color: var(--av-brand-secondary);
                }
            }
            .a-slider__nav {
                margin: 0;
                top: 100%;
                width: 100%;
                display: flex;
                margin-top: 48px;
                position: absolute;
                inset-inline-start: 0;
                justify-content: center;
                @media (--viewport-desktop) {
                    left: 24px;
                    top: auto;
                    width: auto;
                    bottom: 40px;
                    inset-inline-start: 24px;
                }
                @media (--viewport-desktop-wide) {
                    left: 40px;
                }
            }
            .a-slider__controls {
                gap: 16px;
                margin: 0;
                display: none;
                @media (--viewport-desktop) {
                    display: flex;
                    margin-inline-end: auto;
                }
            }
            .a-slider__control {
                margin: 0;
            }
            .a-link {
                padding-top: 16px;
                @media (--viewport-tablet) {
                    margin-top: auto;
                }
            }
        }
    }
}
</style>
