/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.main-page-partners {
    padding: 24px 0;
}

@media (min-width: 1280px) {

.main-page-partners {
        padding: 40px
}
    }

.main-page-partners .title {
        font-size: 18px;
        line-height: 24px;
        padding: 0 32px;
        text-align: center;
        color: var(--av-fixed-primary);
    }

@media (min-width: 768px) {

.main-page-partners .title {
            padding: 0;
            font-size: 24px;
            line-height: 32px
    }}

@media (min-width: 1024px) {

.main-page-partners .title {
            font-size: 32px;
            line-height: 40px
    }}

.main-page-partners .content {
        margin-top: 32px;
    }

.main-page-partners .slider-wrapper:deep(.a-slider) {
            width: 296px;
            overflow: hidden;
            margin: 0 auto;
        }

@media (min-width: 576px) {

.main-page-partners .slider-wrapper:deep(.a-slider) {
                width: 454px
        }
            }

@media (min-width: 1024px) {

.main-page-partners .slider-wrapper:deep(.a-slider) {
                width: 612px
        }
            }

@media (min-width: 1280px) {

.main-page-partners .slider-wrapper:deep(.a-slider) {
                width: 928px
        }
            }

@media (min-width: 1440px) {

.main-page-partners .slider-wrapper:deep(.a-slider) {
                width: 1086px
        }
            }

.main-page-partners .slider-wrapper:deep(.a-slider) {
            position: initial;
}

.main-page-partners .slider-wrapper:deep(.a-slider) .a-slide {
                opacity: 1;
                display: flex;
                height: 76px;
                width: 138px;
                align-items: center;
                justify-content: center;
            }

.main-page-partners .slider-wrapper:deep(.a-slider) .a-slide .a-picture__img {
                    max-width: 119px;
                    max-height: 76px;
                }

.main-page-partners .slider-wrapper:deep(.a-slider) .a-slider__dot::before {
                    background-color: var(--av-nav-primary);
                }

.main-page-partners .slider-wrapper:deep(.a-slider) .a-slider__dot_active::before {
                    background-color: var(--av-brand-secondary);
                }

.main-page-partners .slider-wrapper:deep(.a-slider) .a-slider__nav {
                justify-content: center;
            }

@media (min-width: 768px) {

.main-page-partners .slider-wrapper:deep(.a-slider) .a-slider__nav {
                    margin: 0
            }
                }

.main-page-partners .slider-wrapper:deep(.a-slider) .a-slider__dots {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }

@media (min-width: 768px) {

.main-page-partners .slider-wrapper:deep(.a-slider) .a-slider__dots {
                    display: none
            }
                }

.main-page-partners .slider-wrapper:deep(.a-slider) .a-slider__controls {
                display: none;
            }

@media (min-width: 768px) {

.main-page-partners .slider-wrapper:deep(.a-slider) .a-slider__controls {
                    bottom: 0;
                    margin: 0;
                    z-index: 0;
                    height: 76px;
                    display: flex;
                    width: auto;
                    position: absolute;
                    align-items: center;
                    inset-inline-end: 0;
                    inset-inline-start: 0;
                    background: transparent;
                    justify-content: space-between;
                    padding: 0 var(--container-paddings-mobile-wide)
            }
                }

@media (min-width: 1280px) {

.main-page-partners .slider-wrapper:deep(.a-slider) .a-slider__controls {
                    padding: 0 var(--container-paddings-desktop-wide)
            }
                }

@media (min-width: 1440px) {

.main-page-partners .slider-wrapper:deep(.a-slider) .a-slider__controls {
                    padding: 0
            }
                }

.main-page-partners .slider-wrapper:deep(.a-slider) .a-slider__controls .a-slider__control {
                    margin: 0;
                }

.main-page-partners .slider-wrapper:deep(.a-slider) .a-slider__wrapper  {
                z-index: 1;
            }

.main-page-partners .slider-wrapper:deep(.a-slider).is-control-disabled .a-slider__control {
                    display: none;
                }
